export default function (suffix) {
  let siteName = import.meta.env.VITE_SITE_NAME;
  if(typeof window.vue.$store.state.app.siteName !== 'undefined') {
    siteName = window.vue.$store.state.app.siteName
  }
  if(typeof window.vue.$store.state.app.siteNameTranslations !== 'undefined' && typeof window.vue.$store.state.language !== 'undefined') {
    if(typeof window.vue.$store.state.app.siteName[window.vue.$store.state.language] !== 'undefined') {
      siteName = window.vue.$store.state.app.siteName[window.vue.$store.state.language]
    }
  }

  let separator = '-';
  if (['bo', 'devbo'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
    separator = '|';
  }
  let title = '';
  if (suffix === '' || typeof suffix === 'undefined') {
    title = siteName;
  } else {
    title = `${siteName} ${separator} ${suffix}`;
  }
  return title;
}
